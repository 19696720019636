// @flow
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import Layout from '_components/Layout';
import SignInOutSection from '_components/SignInOutSection';
import { useAuthState } from '_contexts/auth';
import useAnalytics, { event } from '_hooks/useAnalytics';
import { useQueryParams } from '_hooks/useQueryParams';

const Login = (): React$Node => {
  const { track } = useAnalytics();

  const { loading, currentUser } = useAuthState();
  const next = useQueryParams('next');

  useEffect(() => {
    track({
      group: event.group.Login,
      action: event.action.Viewed,
    });
  }, [track]);

  useEffect(() => {
    if (loading || !currentUser) return;

    if (next) {
      navigate(next);
    } else {
      navigate('/profile');
    }
  }, [loading, currentUser]);

  return (
    <Layout floatingNavbar={false} inverseFooter navbarTemplate="sign-in">
      <SignInOutSection mode="signin" />
    </Layout>
  );
};

export default Login;
