// @flow
import { gql } from 'graphql-request';

import { runQuery } from '_client';
import { GqlError } from '_utils/errors';

const REQUEST_LOGIN_LINK = gql`
  mutation requestLoginEmail($email: String!) {
    requestLoginEmail(email: $email)
  }
`;

export const requestLoginLink = async (email: string): Promise<string> => {
  try {
    const { requestLoginEmail } = await runQuery(REQUEST_LOGIN_LINK, { email });
    return requestLoginEmail;
  } catch (err) {
    throw new GqlError(err);
  }
};

export default requestLoginLink;
