// @flow
import './index.less';

import {LockOutlined, MailFilled, MailOutlined} from '@ant-design/icons';
import {yupResolver} from '@hookform/resolvers/yup';
import {navigate} from '@reach/router';

import {Alert, Button, Divider, Spin} from 'antd';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';

import FacebookButton from '_components/FacebookButton';
import WrappedInput from '_components/WrappedInputs/input';
import WrappedPassword from '_components/WrappedInputs/password';
import {useAuth} from '_contexts/auth';
import {useQueryParams} from '_hooks/useQueryParams';
import {requestLoginLink} from '_utils/auth/requestLoginLink';

const emailValidationSchema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string().required(),
});

const linkValidationSchema = yup.object().shape({
  email: yup.string().email(),
});

type Props = {
  disabled?: boolean,
};

const SignInForm = ({disabled = false}: Props): React$Node => {
  const {t} = useTranslation();

  const {signInWithEmail, signInWithMagicLink} = useAuth();
  const magicLinkCode = useQueryParams('code');

  const [signInError, setSignInError] = useState(null);
  const [loginLinkError, setLoginLinkError] = useState(null);

  const [requestLinkError, setRequestLinkError] = useState(false);
  const [requestLinkSuccess, setRequestLinkSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loginLinkLoading, setLoginLinkLoading] = useState(false);

  const [signInWithEmailExpanded, setSignInWithEmailExpanded] = useState(false);
  const [signInWithMagicLinkExpanded, setSignInWithMagicLinkExpanded] = useState(false);

  const {
    control: loginControl,
    errors: loginFormErrors,
    handleSubmit: handleLoginSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(emailValidationSchema),
  });

  const {
    control: linkControl,
    errors: linkFormErrors,
    handleSubmit: handleLinkSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(linkValidationSchema),
  });

  const submitMagicLink = useCallback(
    code => {
      (async () => {
        setLoginLinkLoading(true);
        try {
          await signInWithMagicLink(code);
          setLoginLinkError(null);
        } catch (err) {
          setLoginLinkError(err);
        }
        setLoginLinkLoading(false);
      })();
    },
    [signInWithMagicLink],
  );

  useEffect(() => {
    if (!magicLinkCode) return;
    submitMagicLink(magicLinkCode);
  }, [magicLinkCode, submitMagicLink]);

  const onLoginSubmit = useCallback(
    values => {
      (async () => {
        setLoading(true);
        try {
          await signInWithEmail(values.email.toLowerCase(), values.password);
          setSignInError(null);
        } catch (err) {
          setSignInError(err);
        }
        setLoading(false);
      })();
    },
    [signInWithEmail],
  );

  const onRequestLinkSubmit = useCallback(values => {
    (async () => {
      setLoading(true);
      setLoginLinkError(null);
      try {
        await requestLoginLink(values.email.toLowerCase());
        setRequestLinkError(null);
        setRequestLinkSuccess(true);
      } catch (err) {
        setRequestLinkError(err);
      }
      setLoading(false);
    })();
  }, []);

  if (requestLinkSuccess) {
    return (
      <div className="signin-form">
        <div className="request-link-container">
          <h1>{t('SignInSection_RequestLinkSuccess_Title')}</h1>
          <p>{t('SignInSection_RequestLinkSuccess_Desc')}</p>
        </div>
      </div>
    );
  }

  if (loginLinkLoading) {
    return (
      <div className="signin-form">
        <div className="request-link-container">
          <Spin />
          <p>{t('SignInSection_RequestLinkLoading_Desc')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="signin-form">
      <div className="signin-container">
        {/* G: Show error when logging in with magic link fails */}
        {loginLinkError && (
          <Alert
            message={signInError?.message}
            description={t('SignInSection_RequestLinkError_Desc')}
            type="error"
            style={{textAlign: 'left', marginBottom: '1rem', color: '#b00015'}}
          />
        )}

        <FacebookButton type="signin" disabled={disabled} />

        <Divider className="divider" plain>
          {t('Common_Or')}
        </Divider>

        {signInWithEmailExpanded ? (
          <form onSubmit={handleLoginSubmit(onLoginSubmit)}>
            <div className="subtitle">{t('SignInSection_SignInWithEmail')}</div>
            <Controller
              as={WrappedInput}
              name="email"
              defaultValue={null}
              control={loginControl}
              errors={loginFormErrors}
              width="100%"
              placeholder={t('Placeholder_Email')}
              labelCol={24}
              wrapperCol={24}
              visibilityToggle
              prefix={<MailOutlined className="input-icon" />}
              size="large"
            />

            <Controller
              as={WrappedPassword}
              name="password"
              defaultValue={null}
              control={loginControl}
              errors={loginFormErrors}
              width="100%"
              placeholder={t('Placeholder_Password')}
              labelCol={24}
              wrapperCol={24}
              visibilityToggle
              prefix={<LockOutlined className="input-icon" />}
              size="large"
              style={{marginBottom: 40}}
            />
            <Button className="signin-btn" type="primary" htmlType="submit" loading={loading} block>
              {t('Common_SignIn')}
            </Button>

            {signInError && (
              <Alert
                message={signInError?.message}
                description={t('Error_SignIn')}
                type="error"
                style={{textAlign: 'left', marginTop: '1rem', color: '#b00015'}}
              />
            )}
          </form>
        ) : (
          <Button
            className="signin-btn"
            icon={<MailFilled className="signin-icon" />}
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            onClick={() => {
              setSignInWithMagicLinkExpanded(false);
              setSignInWithEmailExpanded(true);
            }}
            disabled={disabled}
          >
            {t('SignInSection_SignInWithEmail')}
          </Button>
        )}

        <Divider className="divider" plain>
          {t('Common_Or')}
        </Divider>

        {signInWithMagicLinkExpanded ? (
          <form onSubmit={handleLinkSubmit(onRequestLinkSubmit)}>
            <div className="subtitle">{t('SignInSection_GetEmailLoginLink')}</div>
            <Controller
              as={WrappedInput}
              name="email"
              defaultValue={null}
              control={linkControl}
              errors={linkFormErrors}
              width="100%"
              placeholder={t('Placeholder_Email')}
              labelCol={24}
              wrapperCol={24}
              visibilityToggle
              prefix={<MailOutlined className="input-icon" />}
              size="large"
            />

            <Button className="signin-btn" type="primary" htmlType="submit" loading={loading} block>
              {t('Common_Submit')}
            </Button>

            {requestLinkError && (
              <Alert
                message={requestLinkError?.message}
                description={t('Error_RequestLink')}
                type="error"
                style={{textAlign: 'left', marginTop: '1rem', color: '#b00015'}}
              />
            )}
          </form>
        ) : (
          <Button
            className="signin-btn"
            icon={<MailFilled className="signin-icon" />}
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            onClick={() => {
              setSignInWithMagicLinkExpanded(true);
              setSignInWithEmailExpanded(false);
            }}
            disabled={disabled}
          >
            {t('SignInSection_GetEmailLoginLink')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignInForm;
