// @flow
import './index.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';

import FlexBlock from '_components/FlexBlock';
import Section from '_components/Section';
import SectionName from '_components/SectionName';
import SectionTitle from '_components/SectionTitle';
import SignInForm from '_components/SignInForm';
import SignUpForm from '_components/SignUpForm';

type Props = {
  mode: 'signin' | 'signup',
};

const SignInOutSection = ({ mode }: Props): React$Node => {
  const { t } = useTranslation();

  const sectionName = useMemo(() => (mode === 'signin' ? t('SignInSection_Name') : t('SignUpSection_Name')), [t, mode]);

  const title = useMemo(() => (mode === 'signin' ? t('SignInSection_Title') : t('SignUpSection_Title')), [t, mode]);

  const description = useMemo(
    () => (mode === 'signin' ? t('SignInSection_Description') : t('SignUpSection_Description')),
    [t, mode]
  );

  return (
    <Section>
      <div className="sign-in-out-container">
        <SectionName>{sectionName}</SectionName>
        <SectionTitle className="title">{title}</SectionTitle>
        <Markdown className="description">{description}</Markdown>
        <FlexBlock vertical style={{ width: '100%', maxWidth: 500 }}>
          {mode === 'signin' ? <SignInForm /> : <SignUpForm />}
        </FlexBlock>
      </div>
    </Section>
  );
};

export default SignInOutSection;
